import React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layouts/layout'
import HeadMeta from '../components/layouts/headmeta'
import ProgressBar from '../components/layouts/progressbar'
import Content, { HTMLContent } from '../components/layouts/content'
import ParticlesBackground from '../components/layouts/particlesbackground'
import { changeProgressBarOnScroll } from '../components/utils/index'

export default class MinimalPage extends React.Component {
  componentDidMount() {
    window.addEventListener('scroll', function() {
      changeProgressBarOnScroll()
    });

    window.analytics.page('Static', this.props.data.markdownRemark.frontmatter.title)
  }

  render() {
    const { markdownRemark: post } = this.props.data

    const contentComponent = HTMLContent
    const {title, metadata} = post.frontmatter
    const content = post.html
    const PageContent = contentComponent || Content

    return (
      <Layout>
        <div className="section">
          <HeadMeta metadata={metadata}/>
          <ProgressBar />
          <section className="hero bg-gradient-pink-orange text-white" style={{height: '55vh'}}>
            <ParticlesBackground />
            <div className="container">
              <div className="text-center">
                <h1>{title}</h1>
              </div>
            </div>
          </section>
          <section className="container">
            <div className="section">
              <PageContent className="content" content={content} />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const minimalPageQuery = graphql`
  query MinimalPage($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        metadata {
          title
          description
          url
          image
        }
      }
    }
  }
`
